@import '../node_modules/bootstrap/scss/bootstrap.scss';

$text-color: #ecf1ed;
$social-icons: #4fd1c5;
$stars: $social-icons;
$orange-accent: #f3b94d;

.navbar {
  background-color: #1c1e1f;
  flex-direction: row;
  align-self: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  @media (min-width: 500px) {
    height: 10%;
  }
}

.navbar-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 2rem;
  height: 60rem;

  @media (min-width: 500px) {
    flex-direction: row;
    align-self: center;
    align-items: center;
    height: 20em;
  }
  @media (min-width: 800px) {
    flex-direction: row;
    align-self: center;
    align-items: center;
    height: 10em;
  }
}

.navbar-toggle-icon {
  display: flex;
  flex: 1;
}

.navbar-brand {
  display: flex;
  margin: 0 auto;
  font-size: 2.2rem;
}
.navbar-toggler {
  position: absolute;
  right: 0;
  top: 1rem;
  margin-right: 2rem;
}

.nav-link {
  display: flex;
  justify-content: space-around;
  font-size: 1.8rem;
  line-height: 7rem;
  margin-top: -17rem;

  @media (min-width: 500px) {
    display: flex;
    font-size: 1.3rem;
    line-height: 0;
    margin-right: 2em;
    margin-top: -17rem;
  }
  @media (min-width: 800px) {
    display: flex;
    font-size: 1.3rem;
    line-height: 0;
    margin-right: 2em;
    margin-top: -17rem;
  }
}
.fa-star .fa-github .fa-linkedin {
  color: '#4fd1c5';
}

.github-button-container {

  padding-top: 0rem;;
  margin-top: 0;
  @media (min-width: 400px) {
    padding-top: 0rem;
    margin-top: 0;

  }

  .github-button {
    display: flex;
    align-content: center;
    align-items: center;
    margin: 0rem auto;
    text-decoration: none;
    
    
    
    @media (min-width: 400px) {
      margin: 0 auto;
      
    }
    @media (min-width: 1000px) {
      
    }
    p{
      font-weight: 600;
      

    }
  }
}


.fa-paper-plane {
  color: $stars;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.burger-container{
  display: block;
  height: 2vh;
  margin-top: 0rem;
  width: 100%;
  top: 3rem;
  left: 1rem;
  z-index: 2;
  @media (min-width: 800px) {
    display: none;
  }

  .burger {
    margin-top: 0rem;
    margin-bottom: 4rem;

  }
}

