/* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.45%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

//soft reset 1/2

*,
*:before,
*:after {
  box-sizing: inherit;
}

// colors

// colors 2
$text-color: #ecf1ed;
$social-icons: #74f34d;
$orange-accent: #f3b94d;
$stars: $social-icons;
$bold-text: social-icons;
$background-color: #1c1e1f;
$button-bg-color: $social-icons;

// font sizes

$font-size-small-mobile: 0.875rem;
$font-size-mobile: 1.15rem;
$font-size-tablet: 1.15rem;
$font-size-desktop: 1.18rem;

//  mixins

@mixin button($width) {
  width: $width;
}

// bootstrap
@import './custom.scss';

/*   font-family: 'Dosis', sans-serif;
font-family: 'Karla', sans-serif;
font-family: 'Cabin', sans-serif;
font-family: 'Baloo Bhai', cursive;
font-family: 'Exo 2', sans-serif;  */

html {
  //soft reset 2/2
  box-sizing: border-box;

  body {
    overflow-x: hidden;
    overflow-y: auto;
    background: #1c1e1f;
    .react-pdf__Page__canvas {
      margin: 0 auto;
      margin-top: 3rem;
      max-width: 20rem;
      max-height: 27rem;
      @media (min-width: 500px) {
        max-width: 20rem * 1.5;
        max-height: 27rem * 1.5;
      }
      @media (min-width: 750px) {
        max-width: 20rem * 1.5 * 1.5;
        max-height: 27rem * 1.5 * 1.5;
      }
      @media (min-width: 1000px) {
        max-width: 20rem * 1.5 * 1.5 * 1.5;
        max-height: 27rem * 1.5 * 1.5 * 1.5;
      }
    }
    .react-pdf__Page__annotations.annotationLayer {
      display: none;
    }

    .pdf {
      display: flex;
      justify-content: center;
      align-self: center;
      margin: 0 auto;
      height: 2rem;
      margin: 3rem auto;

      .button-next {
        background-color: orange;
      }
      p {
        margin-left: 2rem;
        margin-right: 2rem;
      }
      .button-back {
        background-color: orange;
      }
      a {
        margin: 0 auto;
      }
    }

    .wrapper {
      // end of header

      #portfolio {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-self: center;
        margin: 0 auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
        height: 100%;
        font-size: 1.2rem;

        @media (min-width: 400px) {
        }

        @media (min-width: 800px) {
        }

        .bold-text {
          color: $bold-text;
        }

        h2 {
          display: flex;
          align-self: center;
          font-family: 'Cabin', sans-serif;
          color: $text-color;
          font-size: 2rem;
          margin-bottom: 3rem;
          padding: 3rem auto;

          @media (min-width: 500px) {
            font-size: 2.5rem;
          }

          @media (min-width: 800px) {
            font-size: 3rem;
          }
        }

        .projects {
          
          display: grid;
          grid-template-columns: 20rem;
          grid-template-rows: 40rem;
          grid-row-gap: 4rem;
          grid-column-gap: 4rem;
          

          @media (min-width: 400px) {
          }

          
          @media (min-width: 600px) {
            
          }
          
          @media (min-width: 900px) {
            display: grid;
            grid-template-columns: 20rem 20rem;
            grid-template-rows: 40rem;
            grid-column-gap: 12rem;
           
          }
          @media (min-width: 1000px) {
            
          }

          @media (min-width: 1600px) {
            display: grid;
            grid-template-columns: 20rem 20rem 20rem;
            grid-template-rows: 40rem 40rem;
            grid-column-gap: 12rem;
          }

          img {
            border: 3px solid $text-color;
            border-radius: 4px;
            height: (20rem * 1*0.5);
            margin: 0 auto;
            margin-bottom: 2rem;
            width: 15rem;

            @media (min-width: 400px) {
              width: 20rem;
            }

            @media (min-width: 500px) {
            }

            @media (min-width: 800px) {
            }

            @media (min-width: 1260px) {
            }
          }

          h3 {
            display: flex;
            justify-content: center;
            font-family: 'Cabin', sans-serif;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 1rem;
            color: $text-color;
            width: 15rem;
            font-size: 1.1rem;
            margin-left: 0rem;
            white-space: nowrap; /* restriction to ocuppy only one line */

            @media (min-width: 400px) {
              font-size: 1.2rem;
              margin-left: 3rem;
            }
            @media (min-width: 500px) {
            }

            @media (min-width: 800px) {
            }

            @media (min-width: 1260px) {
              font-size: 1.6rem;
            }
          }

          .dont-justify {
            text-align: center;
            font-size: 1.6rem;
          }

          button {
            p {
              font-size: $font-size-mobile;
            }
          }
        }
        .project{
          
          display: flex;
          flex-direction: column;
          display: grid;
          grid-template-columns: 20rem;
          grid-template-rows: 2rem 10rem 3rem 4rem 11rem;
          grid-row-gap: 1rem;
          height: 40rem;

         

        }
      }
    }

    //end of #contact
    footer {
      height: 100%;

      p {
        color: whitesmoke;
      }
    }

    .show {
      display: flex !important;
    }

    .hidden {
      display: none !important;
    }
  }
}
